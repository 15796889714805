import React from "react";

import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";

import FavoriteIcon from '@mui/icons-material/Favorite';

import { red } from "@mui/material/colors";

import CardLocal from "../cardLocal";

import FormSection from "../FormSection";

import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import data from "../Data";
import Slide from "./Slide";
import MyComponent from "./Animation";
import flowers from '../images/flowers.gif'
import music from '../images/music.png';
import muz from '../video/piano2.mp3'


const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 550 ? item.imgdesktop : item.imgmobil} height="600px"  >
                       <div className="mask" > </div>
                        <div id="noi" className="row text-center align-items-center" style={{ position: 'relative'}}>
                            <div className="col-12" style={{ maxWidth: '600px', padding: '20px', zIndex:'3' }}>
                                
                                    <h1 style={window.innerWidth >= 550 ? {fontSize: '60px'} : {fontSize: '45px'}}>{item.mireasa}  & {item.mire} </h1>
                                    
                                <h1 style={{ fontSize: '60px' }}>{item.copil} </h1>
                                <h4 style={window.innerWidth >= 550 ? {fontSize: '40px'} : {fontSize: '25px'}}>{item.savedata}<br /><b>{item.data}</b></h4>
                                <Timer />
                            </div>
                           </div>
                        
                       
                    </Parallaximg>
                )
            })}

{/** 

<div className="text-center mt-4 pt-4">
                <span className="text-center">
                    <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" src={music} />
                    <audio src={muz} controls autoPlay loop ></audio>
                </span>
            </div>


<div className="text-center pt-5 m-2">
    <h5>
    Cum să trăieşti frumos fără iubire? <br />
                                    Cum să găseşti în viaţă fericirea?<br />
                                    Cum să păşeşti prin beznă şi prin ploi?<br />
                                    Cum altfel, decât amândoi!
    </h5>

</div>*/}


{/*
{data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="500px"  opacity=".5">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '20px' }}>
                                <h1 style={{ fontSize: '45px' }}>
                                    {item.title}
                                </h1>
<h2>{item.message}</h2>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}*/}

            {/**  <BlogSectionNoi /> */}
            <BlogSectionNasii />
            
       




            {/**   <Slide/>*/}

{data.blogDataOmSuflet.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 550 ? item.imgdesktop : item.imgmobil} height="500px" opacity=".2">
                       <div className="mask" > </div>
                       <div className="row text-center align-items-center mx-lg-5" style={{ position: 'relative' }}>
                            <div className="col-12" style={{ maxWidth: '500px', padding: '20px', zIndex: '3'  }}>
                                <h1 style={{ fontSize: '55px' }}>{item.title}</h1>
                                <h4 className="mb-4">{item.message}</h4>
                                <h5 className="mb-4">{item.message1}</h5>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h4 style={{ fontSize: '25px' }}><b>{item.parintii_nume1}</b></h4>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h4 style={{ fontSize: '25px' }}><b>{item.parintii_nume2}</b></h4>
                                    </div>
                                </div>
                                <FavoriteIcon sx={{ color: red[800], fontSize: 40 }} />
                                <h4 className="mb-4 mt-3" style={{ fontSize: '20px' }} >{item.nasii}</h4>
                                <h4 style={{ fontSize: '25px' }}><b>{item.nasii_nume}</b></h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}



            <CardLocal />

            {data.blogDataConfirmare.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 550 ? item.imgdesktop : item.imgmobil} height="400px" opacity=".5">
                        <div className="mask" > </div>
                        <div className="row text-center align-items-center" style={{ position: 'relative' }}>
                        
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '20px', zIndex: '3' }}>
                                <h1 style={{ fontFamily:'Dancing Script',fontSize: '40px' }}>{item.title}</h1>
                                <h4 ><b>{item.message}</b></h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}


            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div className="text-center mb-5">
                        <h4 className="text-center mt-5" >
                            Vă așteptăm cu drag!
                        </h4>
                        
                        <h3 className="text-center " style={{ fontFamily: 'Monsieur La Doulaise', fontSize: '40px' }}>
                            {item.mireasa} & {item.mire}
                        </h3>
                        <img  id="svg" src={flowers} style={{width: '150px'}}/>
                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;