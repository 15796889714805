import restaurant from "./images/grand.jpg";
import biserica from "./images/bis.jpg";
import img_card from "./images/123.jpg";
import imgOmSuflet from "./images/inel.jpg";
import imgOmSufletmb from "./images/inel5.png";
import imgheader from "./images/rose1.jpg";
import imgheadermb from "./images/rose1.jpg";
import img2 from "./images/caterina.jpg";
import imgheadermiini from "./images/fr2.jpg";
import imgheadermiinimb from "./images/fr2.jpg";
import imgconfirmare from "./images/fonrose4.jpeg";



const data = {
    introData: [{
        familia: "Artur & Ecaterina",
        mire: "Ecaterina",
        mireasa: "Artur",
        copil: '',
        data: "8 Septembrie 2024",
        data_confirmare: "30 aprilie 2024",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "ecaterinacdr@gmail.com", 
       tel: "+49 176 61044728 ",
       phone: "tel:+4917661044728",
       viber: "viber://chat?number=%2B4917661044728",
       whatsapp: "https://wa.me/+4917661044728",
       messenger: "https://www.messenger.com/t/codreanu.catea",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/codreanu.catea",
    }],
    cardData: [
       
        {
            id: 1,
            img: restaurant,
            title: "Petrecerea Evenimentelor",
            localul: "Restaurantul",
            name: "Grand Elysée",
            data: "8 septembrie 2024, duminică ",
            sala: 'Sala Eiffel',
            ora: "ora 17:00",
            adress: "Strada Chișinăului 45, Sîngera",
            harta: "https://maps.app.goo.gl/MU3WFVGz3p9TPXiX9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10899.29043056449!2d29.0053241!3d46.9258694!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c976fc6938dfcb%3A0x8d6e300c210a912e!2sGrand%20Elys%C3%A9e!5e0!3m2!1sro!2s!4v1704751686335!5m2!1sro!2s" 
        },
       

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "Cununie și botez!",
            mami: "",
            tati: "La 2 în 1 vă poftim!",
            message: "Foaie verde de trifoi,     ",
            message1: "Super promoție la noi:",
            message2: "Chiar așa! Ce, nu ne crezi?",
            message3: "Hai să vezi că nu glumim.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Vă invităm la celebrarea căsătoriei noastre!",
            message: "Pentru că cele mai frumoase momente se sărbătoresc alături de cei dragi, vrem să fim înconjurați de cei mai apropiați și scumpi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            message1: "",
        }
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgOmSuflet,
            imgmobil: imgOmSufletmb,
            title: "",
            message: "",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Artur și Raisa ",
            parintii_nume2: "Bunicuța Liuba",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Alexandrin şi Doina",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: `De aici începe totul.`,
            message: "\"Dragostea înseamnă un singur suflet care sălăsluiește în două corpuri.\"– Aristotel",
        }
    ],

    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],

}

export default data;